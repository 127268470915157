import {
  TAnalyticsData,
  TAnalyticsLoadboardTimeGraph,
  TAnalyticsPerformanceGraph,
  TAnalyticsPeriod,
  TAnalyticsQueriesFilters,
  TAnalyticsQueryCriteria,
  TAnalyticsQueryResponse,
} from "@/types";
import { callAnalyticApi } from "./api.service";

export async function getPhoneAnalyticsData(
  period: TAnalyticsPeriod
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/analytic/dashboard/phones`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}

export async function getEmailAnalyticsData(
  period: TAnalyticsPeriod
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/analytic/dashboard/emails`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}

export async function getAVGPostToContactTimeAnalyticsData(
  period: TAnalyticsPeriod
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/analytic/dashboard/avgPostToContactTime`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}

export async function getAVGProductiveTimeAnalyticsData(
  period: TAnalyticsPeriod
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/analytic/dashboard/avgProductiveTime`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}

export async function getAVGRateAnalyticsData(
  period: TAnalyticsPeriod
): Promise<TAnalyticsData> {
  const resp = await callAnalyticApi({
    url: `/analytic/dashboard/avgRate`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}

export async function getAllQueriesFilters(): Promise<TAnalyticsQueriesFilters> {
  const resp = await callAnalyticApi({
    url: `/analytic/queries`,
    method: "get",
  });

  return resp.data.data;
}

export async function getAllQueries(
  criteria: TAnalyticsQueryCriteria
): Promise<TAnalyticsQueryResponse> {
  const resp = await callAnalyticApi({
    url: `/analytic/queries/all`,
    method: "post",
    data: criteria,
  });

  return resp.data;
}

export async function getLoadboardTimeGraph(): Promise<TAnalyticsLoadboardTimeGraph> {
  const resp = await callAnalyticApi({
    url: `/load-board/time`,
    method: "get",
  });

  return resp.data;
}

export async function getPerformanceGraph(
  period: TAnalyticsPeriod
): Promise<TAnalyticsPerformanceGraph> {
  const resp = await callAnalyticApi({
    url: `/load-board/performance`,
    method: "get",
    params: {
      filter: period,
    },
  });

  return resp.data;
}
