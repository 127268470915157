import { setQueriesPagination } from "@/redux/slices/analytics-slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Pagination } from "@nextui-org/react";

export const InquiriesTableFooter = () => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(
    (state) => state.analyticsReducer.queriesPagination
  );

  const startValue = pagination.pageNumber * pagination.pageSize + 1;
  const endValue = Math.min(
    (pagination.pageNumber + 1) * pagination.pageSize,
    pagination.totalElements
  );

  const handlePageChange = (page: number) => {
    dispatch(setQueriesPagination({ ...pagination, pageNumber: page - 1 }));
  };

  return (
    <div className="w-full h-9 flex justify-between items-center">
      <span>
        Showing: {startValue}-{endValue} of {pagination.totalElements}
      </span>
      <Pagination
        showControls
        color="default"
        isDisabled={false}
        onChange={handlePageChange}
        page={pagination.pageNumber + 1}
        total={pagination.totalPages}
        variant="light"
        classNames={{
          base: "w-fit p-0 m-0",
          cursor: "bg-transparent border font-bold",
        }}
      />
    </div>
  );
};
