import { FC } from "react";
import { PercentageStatus } from "@/components/percentage-status";

type TooltipModel = {
  id: number;
  caretX: number;
  caretY: number;
};

export type TooltipCountData = {
  value: number | string;
  percentage: number;
  up: boolean;
};

export const LoadboardGraphTooltip: FC<{
  tooltipModel: TooltipModel | null;
  data: {
    phones: TooltipCountData[];
    emails: TooltipCountData[];
    times: TooltipCountData[];
  };
}> = ({ tooltipModel, data }) => {
  if (!tooltipModel) {
    return null;
  }

  const tooltipStyles: any = {
    left: `${tooltipModel.caretX + 8}px`,
    top: `${tooltipModel.caretY + 20}px`,
  };

  return (
    <div
      style={tooltipStyles}
      className="z-50 flex flex-col p-2 bg-white rounded-xl absolute drop-shadow w-fit min-w-[100px]"
    >
      <div className=" absolute top-1/2 -translate-y-1/2 -left-[10px] triangle"></div>
      <div className="flex gap-2 items-center">
        <div className="w-2 h-2 rounded-full bg-[#1e3a7c]"></div>
        <p className="text-gray-950 text-sm">
          {data.times[tooltipModel.id].value}
        </p>
        {data.times[tooltipModel.id].percentage.toString() === "N/A" ? (
          <></>
        ) : (
          <PercentageStatus
            value={data.times[tooltipModel.id].percentage}
            isUp={data.times[tooltipModel.id].up}
          />
        )}
      </div>
      <div className="flex gap-2 items-center">
        <div className="w-2 h-2 rounded-full bg-[#1848B5]"></div>

        <p className="text-gray-950 text-sm">
          {data.emails[tooltipModel.id].value} emails
        </p>
        {data.emails[tooltipModel.id].percentage.toString() === "N/A" ? (
          <></>
        ) : (
          <PercentageStatus
            value={data.emails[tooltipModel.id].percentage}
            isUp={data.emails[tooltipModel.id].up}
          />
        )}
      </div>
      <div className="flex gap-2 items-center">
        <div className="w-2 h-2 rounded-full bg-[#1D6FF3]"></div>

        <p className="text-gray-950 text-sm">
          {data.phones[tooltipModel.id].value} calls
        </p>
        {data.phones[tooltipModel.id].percentage.toString() === "N/A" ? (
          <></>
        ) : (
          <PercentageStatus
            value={data.phones[tooltipModel.id].percentage}
            isUp={data.phones[tooltipModel.id].up}
          />
        )}
      </div>
    </div>
  );
};
