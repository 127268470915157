import { ReactComponent as MailIcon } from "@/assets/icons/mail-icon.svg";
import { ReactComponent as PhoneIcon } from "@/assets/icons/phone-icon.svg";
import { ReactComponent as ClockIcon } from "@/assets/icons/clock-check-icon.svg";
import { ReactComponent as CurrencyDollarIcon } from "@/assets/icons/currency-dollar-icon.svg";
import { ReactComponent as StopwatchIcon } from "@/assets/icons/stopwatch-icon.svg";
import { DataCard } from "./DataCard";
import { DateRangeTabs } from "./DateRangeTabs";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Key, useEffect } from "react";
import { TAnalyticsPeriod } from "@/types";
import { cn } from "@nextui-org/react";
import { setStatsPeriod } from "@/redux/slices/analytics-slice";
import { getUserAnalyticsData } from "@/redux/async/getAnalyticsData";
import { toClockTimeBySeconds } from "@/utils/toClockTime";

export const Stats = () => {
  const dispatch = useAppDispatch();
  const period = useAppSelector((state) => state.analyticsReducer.statsPeriod);
  const loading = useAppSelector(
    (state) => state.analyticsReducer.statsLoading
  );
  const phoneData = useAppSelector((state) => state.analyticsReducer.phoneData);
  const emailData = useAppSelector((state) => state.analyticsReducer.emailData);
  const avgPostToContactTimeData = useAppSelector(
    (state) => state.analyticsReducer.avgPostToContactTimeData
  );
  const avgProductiveTimeData = useAppSelector(
    (state) => state.analyticsReducer.avgProductiveTimeData
  );
  const avgRateData = useAppSelector(
    (state) => state.analyticsReducer.avgRateData
  );

  const handlePeriodChange = (value: Key) => {
    dispatch(setStatsPeriod(value as TAnalyticsPeriod));
  };

  const getLastValue = (prevData: any) => {
    return `vs ${prevData} last period`;
  };

  const wrapperCn = cn("grid grid-cols-5 gap-3", {
    "opacity-40": loading,
  });

  useEffect(() => {
    dispatch(getUserAnalyticsData(period));
  }, [dispatch, period]);

  return (
    <div className={wrapperCn}>
      <DateRangeTabs
        className="absolute top-0 right-0"
        tabs={["day", "week", "month"]}
        onSelectionChange={handlePeriodChange}
        loading={loading}
        selectedKey={period}
      />
      <DataCard
        icon={<MailIcon />}
        title="Totally emails initiated"
        value={emailData.total}
        isUp={emailData.up}
        percentage={emailData.percentage}
        lastValue={getLastValue(emailData.comparison_with_the_past)}
      />
      <DataCard
        icon={<PhoneIcon />}
        title="Totally calls initiated"
        value={phoneData.total}
        isUp={phoneData.up}
        percentage={phoneData.percentage}
        lastValue={getLastValue(phoneData.comparison_with_the_past)}
      />
      <DataCard
        icon={<StopwatchIcon />}
        title="Avg post-to-contact time"
        value={toClockTimeBySeconds(+avgPostToContactTimeData.total)}
        isUp={avgPostToContactTimeData.up}
        percentage={avgPostToContactTimeData.percentage}
        lastValue={getLastValue(
          toClockTimeBySeconds(
            avgPostToContactTimeData.comparison_with_the_past
          )
        )}
      />
      <DataCard
        icon={<ClockIcon />}
        title="Avg productive time"
        value={toClockTimeBySeconds(+avgProductiveTimeData.total)}
        isUp={avgProductiveTimeData.up}
        percentage={avgProductiveTimeData.percentage}
        lastValue={getLastValue(
          toClockTimeBySeconds(avgProductiveTimeData.comparison_with_the_past)
        )}
      />
      <DataCard
        icon={<CurrencyDollarIcon />}
        title="Avg rate"
        value={`$ ${avgRateData.total}`}
        isUp={avgRateData.up}
        percentage={avgRateData.percentage}
        lastValue={getLastValue(`$ ${avgRateData.comparison_with_the_past}`)}
        additional={`RPM $ ${avgRateData.rpm}`}
      />
    </div>
  );
};
