import { PercentageStatus } from "@/components/percentage-status";
import React, { FC } from "react";

interface Props {
  icon: React.ReactNode;
  title: string;
  value: number | string;
  percentage: number;
  isUp?: boolean;
  lastValue: number | string;
  additional?: string;
}

export const DataCard: FC<Props> = ({
  icon,
  title,
  value,
  percentage,
  isUp,
  lastValue,
  additional,
}) => {
  return (
    <div className="px-4 py-3 rounded-lg border border-gray-200 flex gap-2 flex-col relative">
      {additional && (
        <span className="text-xs text-gray-500 absolute top-[14px] right-4">
          {additional}
        </span>
      )}
      <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
        <span className="text-primary-500">{icon}</span>
        {title}
      </h4>
      <span className="flex items-baseline gap-1">
        <span className="text-lg font-medium text-gray-950">{value}</span>
        <PercentageStatus value={percentage} isUp={isUp} />
      </span>
      <span className="text-xs text-gray-500">{lastValue}</span>
    </div>
  );
};
