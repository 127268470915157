import { TAnalyticsPeriod } from "@/types";
import { cn, Tab, Tabs, TabsProps } from "@nextui-org/react";
import { FC } from "react";

interface Props extends TabsProps {
  tabs?: TAnalyticsPeriod[];
  loading?: boolean;
}

const tabsLabels = {
  day: "Day",
  week: "Week",
  month: "Month",
  year: "All time",
};

export const DateRangeTabs: FC<Props> = ({
  className,
  tabs,
  onSelectionChange,
  loading,
  selectedKey,
}) => {
  const tabsList = tabs || ["day", "week", "month", "year"];
  const wrapperCn = cn("flex gap-2 items-center", className);

  return (
    <div className={wrapperCn}>
      <Tabs
        radius="lg"
        size="sm"
        classNames={{
          tab: "text-sm",
          panel: "p-0",
        }}
        onSelectionChange={onSelectionChange}
        isDisabled={loading}
        selectedKey={selectedKey}
      >
        {tabsList.map((tab) => (
          <Tab key={tab} value={tab} title={tabsLabels[tab]} />
        ))}
      </Tabs>
    </div>
  );
};
