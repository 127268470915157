import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { PERSIST_STORE_NAME } from "@/config/app.config";
import storage from "redux-persist/lib/storage";

import authReducer from "../slices/auth-slice";
import userInfoReducer from "../slices/user-info-slice";
import billingReducer from "../slices/billing-slice";
import analyticsReducer from "../slices/analytics-slice";
import { useDispatch, useSelector } from "react-redux";

const persistConfig: PersistConfig<any> = {
  key: PERSIST_STORE_NAME,
  keyPrefix: "",
  storage,
  blacklist: ["userInfoReducer", "billingReducer", "analyticsReducer"],
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      authReducer,
      userInfoReducer,
      billingReducer,
      analyticsReducer,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([]),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = (selector: (state: RootState) => any) =>
  useSelector(selector);
