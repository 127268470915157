import { DateRangeTabs } from "./DateRangeTabs";
import { InquiriesTable } from "./InquiriesTable";
import { InquiriesTableFilters } from "./InquiriesTableFilters";
import { InquiriesTableFooter } from "./InquiriesTableFooter";
import { Key } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TAnalyticsPeriod } from "@/types";
import { setQueriesPeriod } from "@/redux/slices/analytics-slice";

export const InquiriesData = () => {
  const dispatch = useAppDispatch();

  const period = useAppSelector(
    (state) => state.analyticsReducer.queriesPeriod
  );
  const loading = useAppSelector(
    (state) => state.analyticsReducer.queriesLoading
  );
  const totalQueries = useAppSelector(
    (state) => state.analyticsReducer.queriesPagination.totalElements
  );

  const handlePeriodChange = (value: Key) => {
    dispatch(setQueriesPeriod(value as TAnalyticsPeriod));
  };

  return (
    <div className="flex gap-4 flex-col px-4 py-3 rounded-lg border border-gray-200">
      <div className="w-full flex justify-between items-center">
        <span>
          All inquiries{" "}
          <span className="text-gray-950 text-lg">{totalQueries}</span>
        </span>
        <DateRangeTabs
          loading={loading}
          onSelectionChange={handlePeriodChange}
          selectedKey={period}
        />
      </div>
      <InquiriesTableFilters />
      <InquiriesTable />
      <InquiriesTableFooter />
    </div>
  );
};
