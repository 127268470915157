import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { SignInLayout } from "@/app/auth/layout";
import SignIn from "@/app/auth/sign-in";
import SignInVerify from "./auth/verify";

import MainLayout from "@/components/layout";
import EmailPage from "./email";
import EmailTemplate from "./emailTemplate";
import EmailTemplateEditor from "./emailTemplateEditor";
import LoadsPage from "./loadInfo";
import LoadTable from "./loadTable";
import Welcome from "./welcome";
import Pricing from "./pricing";
import NotFound from "./notFound";
import GuestLayout from "@/components/layout/guest";
import { setAmplitudeUserDevice } from "@/analytics";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { setCookie } from "react-use-cookie";
import { Analytics } from "./analytics";
// import { MyTeam } from "./myTeam";

const App: React.FC = () => {
  const { isAuth } = useSelector((state: RootState) => state.authReducer.value);

  useEffect(() => {
    setAmplitudeUserDevice();
  }, []);

  useEffect(() => {
    if (isAuth) {
      setCookie("isAuth", "true");
      setCookie("domain", ".loadconnect.io");
      setCookie("path", "/");
    } else {
      setCookie("isAuth", "false");
      setCookie("domain", ".loadconnect.io");
      setCookie("path", "/");
    }
  }, [isAuth]);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Welcome />} />
      </Route>

      <Route path="/sign-in" element={<SignInLayout />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-in/verifying" element={<SignInVerify />} />
      </Route>

      <Route path="/email" element={<MainLayout />}>
        <Route path="/email" element={<EmailPage />} />
      </Route>

      <Route path="/emailTemplate" element={<MainLayout />}>
        <Route path="/emailTemplate" element={<EmailTemplate />} />
      </Route>

      <Route path="/emailTemplateEditor" element={<MainLayout />}>
        <Route path="/emailTemplateEditor" element={<EmailTemplateEditor />} />
        <Route
          path="/emailTemplateEditor/:id"
          element={<EmailTemplateEditor />}
        />
      </Route>

      <Route path="/loadinfo" element={<GuestLayout />}>
        <Route path="/loadinfo/:id" element={<LoadsPage />} />
      </Route>

      <Route path="/load" element={<MainLayout />}>
        <Route path="/load" element={<LoadTable />} />
      </Route>

      <Route path="/analytics" element={<MainLayout />}>
        <Route path="/analytics" element={<Analytics />}></Route>
      </Route>

      {/* <Route path="/my-team" element={<MainLayout />}>
        <Route path="/my-team" element={<MyTeam />}></Route>
      </Route> */}

      <Route path="/pricing" element={<MainLayout />}>
        <Route path="/pricing" element={<Pricing />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
