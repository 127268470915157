import { DataTable } from "@/components/table";
import { Chip, ChipProps, getKeyValue } from "@nextui-org/react";
import { FC, useCallback, useEffect } from "react";

import { ReactComponent as MailIcon } from "@/assets/icons/mail-icon.svg";
import { ReactComponent as PhoneIcon } from "@/assets/icons/phone-icon.svg";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getUserQueries } from "@/redux/async/getAnalyticsData";
import { ISelectOption } from "@/types";

type Column = {
  key: string;
  label: string;
  align?: "start" | "center" | "end";
  allowsSorting?: boolean;
};

type Row = {
  key: string;
  publishedTime: string;
  origin: string;
  destination: string;
  tripMiles: string;
  truckType: string;
  company: string;
  contacts: string;
  rate: string;
  typeOfContact: string;
};

const statusColorMap: Record<string, ChipProps["color"]> = {
  email: "success",
  phone: "warning",
};

const statusIconMap: Record<string, JSX.Element> = {
  email: <MailIcon />,
  phone: <PhoneIcon />,
};

const statusTextMap: Record<string, string> = {
  email: "Email",
  phone: "Call",
};

const sortOrderMap: Record<string, string> = {
  0: "desc",
  1: "asc",
};

const columns: Column[] = [
  { key: "published_time", label: "Published time" },
  { key: "origin", label: "Origin" },
  { key: "destination", label: "Destination" },
  { key: "trip_miles", label: "Trip miles" },
  { key: "truck_type", label: "Truck type" },
  { key: "company", label: "Company" },
  { key: "contacts", label: "Contacts" },
  { key: "rate", label: "Rate" },
  { key: "type_of_contact", label: "Type of contact" },
];

export const InquiriesTable: FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.analyticsReducer.queriesLoading
  );
  const queries = useAppSelector((state) => state.analyticsReducer.queries);
  const period = useAppSelector(
    (state) => state.analyticsReducer.queriesPeriod
  );
  const queriesParams = useAppSelector(
    (state) => state.analyticsReducer.queriesParams
  );
  const queriesPagination = useAppSelector(
    (state) => state.analyticsReducer.queriesPagination
  );
  const queryFilters = useAppSelector(
    (state) => state.analyticsReducer.queriesFilters
  );

  useEffect(() => {
    if (period && queriesParams && queriesPagination) {
      const origin =
        queryFilters.origin.find(
          (filter: ISelectOption) =>
            filter.id === +queriesParams.origin.currentKey
        )?.name || null;

      const destination =
        queryFilters.destination.find(
          (filter: ISelectOption) =>
            filter.id === +queriesParams.destination.currentKey
        )?.name || null;

      const entity =
        queryFilters.entity.find(
          (filter: ISelectOption) =>
            filter.id === +queriesParams.entity.currentKey
        )?.name || null;

      dispatch(
        getUserQueries({
          origin,
          destination,
          entity,
          searchQuery: queriesParams.searchQuery || null,
          sortOrder: sortOrderMap[queriesParams.sortOrder.currentKey],
          period: period,
          page: queriesPagination.pageNumber,
          size: queriesPagination.pageSize,
          pageable: {
            offset: queriesPagination.offset,
            sort: {
              empty: true,
              sorted: true,
              unsorted: true,
            },
            paged: true,
            pageNumber: queriesPagination.pageNumber,
            pageSize: queriesPagination.pageSize,
            unpaged: true,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    period,
    queriesParams.searchQuery,
    queriesParams.sortOrder,
    queriesParams.origin,
    queriesParams.destination,
    queriesParams.entity,
    queriesPagination.pageNumber,
    queriesPagination.pageSize,
    queriesPagination.offset,
  ]);

  const renderCell = useCallback((row: Row, columnKey: React.Key) => {
    const cellValue = getKeyValue(row, columnKey as string);

    switch (columnKey) {
      case "type_of_contact":
        return (
          <Chip
            size="lg"
            radius="lg"
            startContent={statusIconMap[cellValue.toLowerCase()]}
            color={statusColorMap[cellValue.toLowerCase()]}
            variant="flat"
          >
            {statusTextMap[cellValue.toLowerCase()]}
          </Chip>
        );
      case "published_time":
        return new Date(cellValue).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      default:
        return cellValue;
    }
  }, []);

  return (
    <DataTable
      rows={queries}
      columns={columns}
      maxHeight="calc(100vh_-_830px)"
      renderCell={renderCell}
      emptyText="No inquiries found"
      isLoading={loading}
    />
  );
};
