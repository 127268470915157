import { DoughnutChart } from "@/components/doughnut-chart";
import { ChartData } from "chart.js";
import { LoadboardPerformanceItem } from "./LoadboardPerformanceItem";

import { DateRangeTabs } from "./DateRangeTabs";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getPerformanceGraphData } from "@/redux/async/getAnalyticsData";
import { Key, useCallback, useEffect, useMemo } from "react";
import { setPerformancePeriod } from "@/redux/slices/analytics-slice";
import { cn } from "@nextui-org/react";
import { toClockTime } from "@/utils/toClockTime";

const labels = ["DAT", "Sylectus", "Truckstop"];
const colors = ["#1e40af", "#1d4ed8", "#2563eb", "#3b82f6", "#60a5fa"];

export const LoadboardPerformance = () => {
  const dispatch = useAppDispatch();

  const period = useAppSelector(
    (state) => state.analyticsReducer.performancePeriod
  );

  const data = useAppSelector(
    (state) => state.analyticsReducer.performanceGraphData
  );

  const loading = useAppSelector(
    (state) => state.analyticsReducer.performanceGraphLoading
  );

  useEffect(() => {
    dispatch(getPerformanceGraphData(period));
  }, [dispatch, period]);

  const handlePeriodChange = useCallback(
    (value: Key) => {
      dispatch(setPerformancePeriod(value));
    },
    [dispatch]
  );

  const totalTime = useMemo(() => {
    return toClockTime(data.total_time);
  }, [data.total_time]);

  const className = useMemo(
    () =>
      cn(
        "w-full h-[300px] px-4 py-3 rounded-lg border border-gray-200 flex gap-4 flex-col relative",
        {
          "opacity-40": loading,
        }
      ),
    [loading]
  );

  const chartData: ChartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          label: "Time",
          data: data.performance_list.map(
            (item: any) => +item.percentage.replace("%", "")
          ),
          backgroundColor: colors,
        },
      ],
    };
  }, [data.performance_list]);

  return (
    <div className={className}>
      <div className="w-full flex justify-between items-center">
        <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
          Load board performance
        </h4>
        <DateRangeTabs
          selectedKey={period}
          onSelectionChange={handlePeriodChange}
          tabs={["day", "week", "month"]}
        />
      </div>
      <div className="flex gap-8 h-[calc(100%_-_50px)] justify-between relative">
        <div className="flex justify-center items-center w-52 h-52">
          <DoughnutChart chartData={chartData} />
          <div className="flex flex-col absolute items-center">
            <span>general time</span>
            <span className="text-lg text-gray-950">{totalTime}</span>
          </div>
        </div>
        <div className="w-full h-full overflow-auto flex flex-col gap-2">
          {data.performance_list.map((item: any, index: any) => (
            <LoadboardPerformanceItem key={index} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
