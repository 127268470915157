import { RootState } from "@/redux/store";
import { Card, CardBody } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { AddOns } from "./AddOns";

// const FEATURES_TERMS: Record<string, string> = {
//   filters: "Filters",
//   rpmLoadboard: "RPM Calculator",
//   googleMapsIcon: "Google Maps Integration",
//   email: "Send Email Button",
//   loadconnectMap: "LoadConnect Map Button",
//   popup: "Load Info Popup",
//   googleRpm: "Google RPM",
// };

export const ActivePlan = () => {
  const billingStatus = useSelector(
    (state: RootState) => state.billingReducer.status
  );

  return (
    <div className="w-full flex flex-col gap-5">
      <h4 className="font-medium">Billing & usage</h4>
      <Card radius="lg" shadow="none" className="border">
        <CardBody className="w-full flex justify-between px-5 py-4">
          <div className="flex flex-col gap-1">
            <h5>Your Plan is {billingStatus}</h5>
            <p className="text-gray-950">
              You have unlimited email, all our paid functions
            </p>
            <p className="text-gray-400">
              Need any help?{" "}
              <a
                className="underline italic"
                href="https://loadconnect.io/contact/"
              >
                Contact us
              </a>
            </p>
          </div>
        </CardBody>
      </Card>
      <AddOns />
    </div>
  );
};
