import { ChartData } from "chart.js";
import { FC } from "react";
import { Doughnut } from "react-chartjs-2";

interface Props {
  chartData: ChartData<any>;
}

export const DoughnutChart: FC<Props> = ({ chartData }) => {
  return (
    <Doughnut
      data={chartData}
      options={{
        cutout: 90,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "white",
            bodyColor: "#182230",
            titleColor: "#182230",
            mode: "index",
            axis: "x",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }}
    />
  );
};
