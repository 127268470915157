import { LoadboardTimeGraph } from "./components/LoadboardTimeGraph";
import { Stats } from "./components/Stats";
import { SavedHours } from "./components/SavedHours";
import { DispatchersData } from "./components/DispatchersData";

export const ManagerAnalytics = () => {
  return (
    <>
      <div className="col-span-2">
        <Stats />
      </div>
      <LoadboardTimeGraph />
      <SavedHours />
      <div className="col-span-2">
        <DispatchersData />
      </div>
    </>
  );
};
