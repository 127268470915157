import { Input, SharedSelection } from "@nextui-org/react";
import { ReactComponent as SearchIcon } from "@/assets/icons/search-icon.svg";
import { ReactComponent as MarkerIcon } from "@/assets/icons/marker-icon.svg";
import { ReactComponent as EntityIcon } from "@/assets/icons/entity-icon.svg";
import { FitlersSelect } from "@/components/filters-select";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setQueriesParams } from "@/redux/slices/analytics-slice";
import { debounce } from "lodash";
import { useEffect } from "react";
import { getUserQueriesFilters } from "@/redux/async/getAnalyticsData";
import { ISelectOption } from "@/types";

const sortingOptions: ISelectOption[] = [
  {
    id: 0,
    name: "Newest first",
  },
  {
    id: 1,
    name: "Oldest first",
  },
];

export const InquiriesTableFilters = () => {
  const dispatch = useAppDispatch();
  const queryParams = useAppSelector(
    (state) => state.analyticsReducer.queriesParams
  );
  const queryFilters = useAppSelector(
    (state) => state.analyticsReducer.queriesFilters
  );

  useEffect(() => {
    dispatch(getUserQueriesFilters());
  }, [dispatch]);

  const handleSearchQueryChange = debounce((value: string) => {
    dispatch(
      setQueriesParams({
        ...queryParams,
        searchQuery: value,
      })
    );
  }, 1000);

  const handleFilterChange = (key: string, value?: SharedSelection) => {
    dispatch(
      setQueriesParams({
        ...queryParams,
        [key]: value,
      })
    );
  };

  return (
    <div className="w-full h-10 flex gap-4 justify-between">
      <div className="w-full h-10 flex gap-4">
        <Input
          isClearable
          placeholder="Search"
          variant="bordered"
          className="w-full max-w-80"
          startContent={<SearchIcon />}
          onValueChange={handleSearchQueryChange}
        />
        <FitlersSelect
          label="Origin"
          icon={<MarkerIcon />}
          options={queryFilters.origin}
          selectedKeys={queryParams.origin}
          onSelectionChange={(keys) => handleFilterChange("origin", keys)}
        />
        <FitlersSelect
          label="Destination"
          icon={<MarkerIcon />}
          options={queryFilters.destination}
          selectedKeys={queryParams.destination}
          onSelectionChange={(keys) => handleFilterChange("destination", keys)}
        />
        <FitlersSelect
          className="max-w-40"
          label="Entity"
          icon={<EntityIcon />}
          options={queryFilters.entity}
          selectedKeys={queryParams.entity}
          onSelectionChange={(keys) => handleFilterChange("entity", keys)}
        />
      </div>
      <FitlersSelect
        className="max-w-40"
        label="Sort"
        options={sortingOptions}
        selectedKeys={queryParams.sortOrder}
        onSelectionChange={(keys) => handleFilterChange("sortOrder", keys)}
      />
    </div>
  );
};
