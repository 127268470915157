import {
  getUserBilling,
  getUserBillingType,
  getUserBillingUpdate,
} from "@/server/auth.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBillingInfo = createAsyncThunk(
  "billing/getBillingInfo",
  async () => {
    return await getUserBilling();
  }
);

export const getBillingType = createAsyncThunk(
  "billing/getBillingType",
  async () => {
    return await getUserBillingType();
  }
);

export const getBillingUpdate = createAsyncThunk(
  "billing/getBillingUpdate",
  async () => {
    return await getUserBillingUpdate();
  }
);
