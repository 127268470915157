import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserBillingStatus, UserBillingType } from "@/types/index";
import {
  getBillingInfo,
  getBillingType,
  getBillingUpdate,
} from "../async/getBillingInfo";

interface BillingInfo {
  loading: boolean;
  status: UserBillingStatus | "";
  type: UserBillingType | "";
}

const initialState: BillingInfo = {
  loading: false,
  status: "",
  type: "",
};

export const billingInfo = createSlice({
  name: "billingInfo",
  initialState,
  reducers: {
    setBillingStatus: (state, action: PayloadAction<UserBillingStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBillingInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
    });
    builder.addCase(getBillingInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBillingType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBillingType.fulfilled, (state, action) => {
      state.loading = false;
      state.type = action.payload;
    });
    builder.addCase(getBillingType.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBillingUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBillingUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
      window.location.reload();
    });
    builder.addCase(getBillingUpdate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setBillingStatus } = billingInfo.actions;

export default billingInfo.reducer;
