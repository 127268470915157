import { UserBillingStatus, UserBillingType, UserExt, UserInfo } from "@/types";
import { callApi } from "./api.service";

export async function sendAuthEmail(email: string): Promise<boolean> {
  const resp = await callApi("/auth/v2/tokenLink", "post", false, { email });
  return (await resp.data) === "success" ? true : false;
}

export async function verifyTokenLink(data: {
  email: string;
  token: string;
}): Promise<UserExt> {
  const resp = await callApi("/auth/verifyLink", "post", false, data);
  const userExt: UserExt = resp.data;
  return userExt;
}

export async function userMe(): Promise<UserExt> {
  return new Promise((resolve, reject) => {
    callApi("/users/me", "get", true, {})
      .then((resp) => {
        const userExt: UserExt = resp.data;
        resolve(userExt);
      })
      .catch((err) => reject(err));
  });
}

export async function userUnfo(): Promise<UserInfo> {
  return new Promise((resolve, reject) => {
    callApi("/user-info", "get", true, {})
      .then((resp) => {
        const userExt: UserInfo = resp.data;
        resolve(userExt);
      })
      .catch((err) => reject(err));
  });
}

export async function postUserInfo(data: UserInfo): Promise<UserInfo> {
  return new Promise((resolve, reject) => {
    callApi("/user-info", "post", true, data)
      .then((resp) => {
        const userExt: UserInfo = resp.data;
        resolve(userExt);
      })
      .catch((err) => reject(err));
  });
}

export async function getUserBilling(): Promise<UserBillingStatus> {
  return new Promise((resolve, reject) => {
    callApi("/users/billing-status", "get", true, {})
      .then((resp) => {
        const status: UserBillingStatus = resp.data;
        resolve(status);
      })
      .catch((err) => reject(err));
  });
}

export async function getUserBillingType(): Promise<UserBillingType> {
  return new Promise((resolve, reject) => {
    callApi("/users/billing", "get", true, {})
      .then((resp) => {
        const status: UserBillingType = resp.data;
        resolve(status);
      })
      .catch((err) => reject(err));
  });
}

export async function getUserBillingUpdate(): Promise<UserBillingStatus> {
  return new Promise((resolve, reject) => {
    callApi("/users/upgrade", "get", true, {})
      .then((resp) => {
        const status: UserBillingStatus = resp.data;
        resolve(status);
      })
      .catch((err) => reject(err));
  });
}
