import { Input } from "@nextui-org/react";
import { ReactComponent as SearchIcon } from "@/assets/icons/search-icon.svg";

export const DispatchersTableFilters = () => {
  return (
    <div className="h-10 flex gap-4">
      <Input
        isClearable
        placeholder="Search"
        variant="bordered"
        className="w-full max-w-80"
        startContent={<SearchIcon />}
      />
    </div>
  );
};
