import { DateRangeTabs } from "./DateRangeTabs";

import { ReactComponent as ClockIcon } from "@/assets/icons/clock-icon.svg";
import { ReactComponent as SpeedometerIcon } from "@/assets/icons/speedometer-icon.svg";

export const SavedHours = () => {
  return (
    <div className="w-full h-72 px-4 py-3 rounded-lg border border-gray-200 flex gap-3 flex-col relative">
      <div className="w-full flex justify-between items-center">
        <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
          Hours saved per dispatcher
        </h4>
        <DateRangeTabs tabs={["day", "week", "month"]} />
      </div>
      <div className="w-full text-center">
        <p className="text-lg text-gray-950 font-bold">4h 13m</p>
        <p>per day</p>
      </div>
      <div>
        <h4 className="text-primary-500 text-base font-medium flex gap-1 items-center mb-1">
          <ClockIcon />
          Booking time
        </h4>
        <div className="w-full grid grid-cols-2">
          <div>
            <h5 className="text-sm font-normal text-gray-500">
              With LoadConnect
            </h5>
            <p className="text-gray-950">2h 12m</p>
          </div>
          <div>
            <h5 className="text-sm font-normal text-gray-500">
              Without LoadConnect
            </h5>
            <p className="text-gray-950">3h 48m</p>
          </div>
        </div>
      </div>
      <div>
        <h4 className="text-primary-500 text-base font-medium flex gap-1 items-center mb-1">
          <SpeedometerIcon />
          Load board usage
        </h4>
        <div className="w-full grid grid-cols-2">
          <div>
            <h5 className="text-sm font-normal text-gray-500">
              With LoadConnect
            </h5>
            <p className="text-gray-950">2h 12m</p>
          </div>
          <div>
            <h5 className="text-sm font-normal text-gray-500">
              Without LoadConnect
            </h5>
            <p className="text-gray-950">3h 48m</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Booking time
// With LoadConnect
// Without LoadConnect
// 2h 12m
// 3h 48m
// Load board usage
// With LoadConnect
// Without LoadConnect
// 2h 12m
// 3h 48m
