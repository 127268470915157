import { FC } from "react";
import { PercentageStatus } from "@/components/percentage-status";
import DATLogo from "@/assets/loadboard-icons/dat.svg";
import SylectusLogo from "@/assets/loadboard-icons/sylectus.svg";
import TruckstopLogo from "@/assets/loadboard-icons/truckstop.svg";
import LoadlinkLogo from "@/assets/loadboard-icons/loadlink.svg";
import { toClockTimeBySeconds } from "@/utils/toClockTime";

interface Props {
  data: {
    source: string;
    percentage: string;
    total: string;
    diffPercentage: string;
    up: boolean;
    wasted_time: string;
  };
}

const itemLogoMap: Record<string, string> = {
  dat: DATLogo,
  sylectus: SylectusLogo,
  truckstop: TruckstopLogo,
  loadlink: LoadlinkLogo,
};

const itemNameMap: Record<string, string> = {
  dat: "DAT",
  sylectus: "Sylectus",
  truckstop: "Truckstop",
  loadlink: "LoadLink",
};

const itemRegex = /\b(dat|sylectus|truckstop|loadlink)\b/gi;

export const LoadboardPerformanceItem: FC<Props> = ({ data }) => {
  const { source, percentage, total, diffPercentage, up, wasted_time } = data;

  const loadboard = source.match(itemRegex)?.[0].toLowerCase() || "";

  return (
    <div className="grid grid-cols-[24px_1fr] gap-4 border-b pb-2 relative last:border-b-0 last:pb-0">
      <img src={itemLogoMap[loadboard]} alt="logo" className="w-6 h-6" />
      <div className="flex flex-col">
        <h4 className="text-sm">{itemNameMap[loadboard]}</h4>
        <p>
          Totally inquiry initiated
          <span className="font-medium ml-2 text-gray-950">{total}</span>
        </p>
        <p className="flex items-baseline gap-1">
          {diffPercentage === "N/A" ? (
            diffPercentage
          ) : (
            <>
              <PercentageStatus value={diffPercentage} isUp={up} /> vs last
              period
            </>
          )}
        </p>
      </div>
      <span className="absolute top-0 right-0 flex gap-2 items-center">
        <span className="text-gray-950">
          {toClockTimeBySeconds(+wasted_time)}
        </span>
        {percentage}
      </span>
    </div>
  );
};
