import { useEffect, useMemo } from "react";
import { RootState, useAppDispatch, useAppSelector } from "@/redux/store";
import { ActivePlan } from "./components/ActivePlan";
import { ErrorPlan } from "./components/ErrorPlan";
import { Loader } from "@/components/loader";
import { getBillingInfo, getBillingType } from "@/redux/async/getBillingInfo";
import { PlansNew } from "./components/PlansNew";

const Pricing = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    (state: RootState) => state.billingReducer.status
  );

  const loading = useAppSelector(
    (state: RootState) => state.billingReducer.loading
  );

  useEffect(() => {
    dispatch(getBillingInfo());
    dispatch(getBillingType());
  }, [dispatch]);

  const renderUIBasedOnBillingStatus = useMemo(() => {
    switch (status) {
      case "past_due":
      case "unpaid":
        return <ErrorPlan />;
      case "paused":
      case "active":
        return <ActivePlan />;
      case "inactive":
      default:
        return <PlansNew />;
    }
  }, [status]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="flex flex-col flex-grow items-center py-6 overflow-auto"
      id="Pricing"
    >
      {renderUIBasedOnBillingStatus}
    </div>
  );
};

export default Pricing;
