export const authenticatedEntryPath = "/email";
export const unAuthenticatedEntryPath = "/sign-in";

export const PERSIST_STORE_NAME = "loadconnect_data";
export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";

export const API_CARRIERIFY_PREFIX =
  process.env.REACT_APP_API_CARRIERIFY_PREFIX || "";
export const API_LOADCONNECT_PREFIX =
  process.env.REACT_APP_API_LOADCONNECT_PREFIX || "";
export const API_ANALYTICS_PREFIX =
  process.env.REACT_APP_API_ANALYTICS_PREFIX || "";
