import { LoadboardTimeGraph } from "./components/LoadboardTimeGraph";
import { LoadboardPerformance } from "./components/LoadboardPerformance";
import { InquiriesData } from "./components/InquiriesData";
import { Stats } from "./components/Stats";

export const UserAnalytics = () => {
  return (
    <>
      <div className="col-span-2">
        <Stats />
      </div>
      <LoadboardTimeGraph />
      <LoadboardPerformance />
      <div className="col-span-2">
        <InquiriesData />
      </div>
    </>
  );
};
