import { Key, useState } from "react";
import { ManagerAnalytics } from "@/components/analytics/ManagerAnalytics";
import { UserAnalytics } from "@/components/analytics/UserAnalytics";
import { Tab, Tabs } from "@nextui-org/react";
import { useAppSelector } from "@/redux/store";
import { Navigate } from "react-router-dom";
import { LockedView } from "@/components/analytics/components/LockedView";

export const Analytics = () => {
  const [userType, setUserType] = useState("user");

  const { features } = useAppSelector((state) => state.authReducer.value);

  const handleUserTypeChange = (value: Key) => {
    setUserType(value as string);
  };

  if (!features.includes("analytic")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="relative grid grid-cols-2 gap-3 mt-4">
      {!features.includes("analytic") && <LockedView />}
      <div className="flex gap-4 items-center col-span-2 h-8">
        <h1 className="font-medium text-xl">Analytics</h1>
        <h2 className="font-normal text-xl">
          {new Date().toLocaleDateString("us-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </h2>
      </div>
      <Tabs
        size="sm"
        radius="lg"
        classNames={{
          base: "absolute -top-16 left-0 z-50 hidden",
          tab: "text-sm",
          panel: "p-0",
        }}
        onSelectionChange={handleUserTypeChange}
        selectedKey={userType}
      >
        <Tab key="owner" title="Owner" />
        <Tab key="user" title="User" />
      </Tabs>
      {userType === "user" ? <UserAnalytics /> : <ManagerAnalytics />}
    </div>
  );
};
