import { CategoryScale, ChartOptions } from "chart.js";
import Chart from "chart.js/auto";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getLoadboardTimeGraphData } from "@/redux/async/getAnalyticsData";
import { Bar } from "react-chartjs-2";
import { cn } from "@nextui-org/react";
import { LoadboardGraphTooltip } from "./LoadboardGraphTooltip";
import {
  toClockTimeBySeconds,
  toTimeObjectBySeconds,
} from "@/utils/toClockTime";

Chart.register(CategoryScale);

type TooltipModel = {
  id: number;
  caretX: number;
  caretY: number;
};
type TooltipCountData = {
  value: number | string;
  percentage: number;
  up: boolean;
};

export const LoadboardTimeGraph = () => {
  const [tooltipModel, setTooltipModel] = useState<TooltipModel | null>(null);
  const dispatch = useAppDispatch();

  const loadboardTimeGraphData = useAppSelector(
    (state) => state.analyticsReducer.loadboardTimeGraphData.data
  );
  const totalTime = useAppSelector(
    (state) => state.analyticsReducer.loadboardTimeGraphData.totalTime
  );
  const loading = useAppSelector(
    (state) => state.analyticsReducer.loadboardTimeGraphLoading
  );

  useEffect(() => {
    dispatch(getLoadboardTimeGraphData());
  }, [dispatch]);

  const totalString = useMemo(() => {
    return toClockTimeBySeconds(totalTime);
  }, [totalTime]);

  const data = useMemo(() => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const timeSpent: number[] = [];
    const times: TooltipCountData[] = [];
    const phones: TooltipCountData[] = [];
    const emails: TooltipCountData[] = [];

    Object.keys(loadboardTimeGraphData).forEach((day) => {
      const dayData = loadboardTimeGraphData[day.toUpperCase()].data;
      const percentage = loadboardTimeGraphData[day.toUpperCase()].percentages;
      const timeParts = toTimeObjectBySeconds(+dayData.spentTime);
      const decimalHours =
        timeParts.hours + timeParts.minutes / 60 + timeParts.seconds / 3600;
      timeSpent.push(decimalHours);

      times.push({
        value: toClockTimeBySeconds(+dayData.spentTime),
        percentage: percentage.spentTime.percent,
        up: percentage.spentTime.up,
      });
      phones.push({
        value: dayData.phones,
        percentage: percentage.PHONE.percent,
        up: percentage.PHONE.up,
      });
      emails.push({
        value: dayData.emails,
        percentage: percentage.EMAIL.percent,
        up: percentage.EMAIL.up,
      });
    });

    const data = {
      labels: daysOfWeek,
      datasets: [
        {
          label: "Time",
          data: timeSpent,
          backgroundColor: "#1e3a7c",
          barThickness: 15,
          borderRadius: 5,
        },
      ],
    };

    return {
      data,
      phones,
      emails,
      times,
    };
  }, [loadboardTimeGraphData]);

  const options: ChartOptions<"bar"> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: function ({ tooltip }) {
            if (tooltip.opacity === 0) {
              setTooltipModel(null);
            } else {
              setTooltipModel({
                id: tooltip.dataPoints[0].parsed.x,
                caretX: tooltip.caretX,
                caretY: tooltip.caretY,
              });
            }
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
          },
          min: 0,
          beginAtZero: true,
          title: {
            display: true,
            text: "Hours",
          },
        },
      },
    }),
    []
  );

  const className = cn(
    "w-full h-[300px] px-4 py-3 rounded-lg border border-gray-200 flex gap-2 flex-col",
    {
      "opacity-40": loading,
    }
  );

  return (
    <div className={className}>
      <div className="flex w-full justify-between s">
        <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
          Time on load board
        </h4>
        <h5 className="text-gray-950 text-lg font-medium">{totalString}</h5>
      </div>

      <div className="w-full h-full relative">
        <Bar data={data.data} options={options} />
        <LoadboardGraphTooltip tooltipModel={tooltipModel} data={data} />
      </div>
    </div>
  );
};
