import { PercentageStatus } from "@/components/percentage-status";
import { DataTable } from "@/components/table";
import { Avatar, getKeyValue } from "@nextui-org/react";
import { useCallback } from "react";

type Column = {
  key: string;
  label: string;
  align?: "start" | "center" | "end";
  allowsSorting?: boolean;
};

type Row = {
  key: string;
  name: string;
  inquiriesSent: string;
  inquiriesSentLastWeekPercentage: string;
  productivityTime: string;
  productivityTimeLastWeekPercentage: string;
  loadSearchTime: string;
  loadSearchTimeLastWeekPercentage: string;
  avgPostToContactTime: string;
  avgPostToContactTimeLastWeekPercentage: string;
  avgRate: string;
  avgRateLastWeekPercentage: string;
  avgRPM: string;
  avgRPMLastWeekPercentage: string;
};

const columns: Column[] = [
  { key: "name", label: "Name", allowsSorting: true },
  { key: "inquiriesSent", label: "Inquiries sent", allowsSorting: true },
  { key: "productivityTime", label: "Productivity time", allowsSorting: true },
  { key: "loadSearchTime", label: "Load search time", allowsSorting: true },
  {
    key: "avgPostToContactTime",
    label: "Avg post-to-contact time",
    allowsSorting: true,
  },
  { key: "avgRate", label: "Avg rate", allowsSorting: true },
  { key: "avgRPM", label: "Avg RPM", allowsSorting: true },
];

const rows: Row[] = [
  {
    key: "1",
    name: "Brooklyn Simmons",
    inquiriesSent: "56",
    inquiriesSentLastWeekPercentage: "-2",
    productivityTime: "5h 12m",
    productivityTimeLastWeekPercentage: "17",
    loadSearchTime: "2h 12m",
    loadSearchTimeLastWeekPercentage: "2",
    avgPostToContactTime: "46s",
    avgPostToContactTimeLastWeekPercentage: "4",
    avgRate: "$2,050.00",
    avgRateLastWeekPercentage: "2",
    avgRPM: "$2.40",
    avgRPMLastWeekPercentage: "2",
  },
  {
    key: "2",
    name: "Guy Hawkins",
    inquiriesSent: "53",
    inquiriesSentLastWeekPercentage: "5",
    productivityTime: "5h 8m",
    productivityTimeLastWeekPercentage: "18",
    loadSearchTime: "2h 45m",
    loadSearchTimeLastWeekPercentage: "10",
    avgPostToContactTime: "56s",
    avgPostToContactTimeLastWeekPercentage: "4",
    avgRate: "$1,700.00",
    avgRateLastWeekPercentage: "2",
    avgRPM: "$2.25",
    avgRPMLastWeekPercentage: "4",
  },
  {
    key: "3",
    name: "Jacob Jones",
    inquiriesSent: "53",
    inquiriesSentLastWeekPercentage: "4",
    productivityTime: "4h 46m",
    productivityTimeLastWeekPercentage: "24",
    loadSearchTime: "2h",
    loadSearchTimeLastWeekPercentage: "14",
    avgPostToContactTime: "44s",
    avgPostToContactTimeLastWeekPercentage: "12",
    avgRate: "$1,500.00",
    avgRateLastWeekPercentage: "5",
    avgRPM: "$2.00",
    avgRPMLastWeekPercentage: "6",
  },
  {
    key: "4",
    name: "Cameron Williamson",
    inquiriesSent: "48",
    inquiriesSentLastWeekPercentage: "2",
    productivityTime: "4h 32m",
    productivityTimeLastWeekPercentage: "8",
    loadSearchTime: "3h 5m",
    loadSearchTimeLastWeekPercentage: "4",
    avgPostToContactTime: "47s",
    avgPostToContactTimeLastWeekPercentage: "5",
    avgRate: "$1,450.00",
    avgRateLastWeekPercentage: "4",
    avgRPM: "$2.10",
    avgRPMLastWeekPercentage: "9",
  },
];

export const DispatchersTable = () => {
  const renderCell = useCallback((row: Row, columnKey: React.Key) => {
    const cellValue = getKeyValue(row, columnKey as string);
    const percentValue = getKeyValue(
      row,
      (columnKey + "LastWeekPercentage") as string
    );

    switch (columnKey) {
      case "name":
        return (
          <span className="flex items-center gap-2">
            <Avatar
              size="sm"
              name={cellValue[0].toUpperCase()}
              classNames={{
                base: "bg-primary-50 text-white text-medium text-primary-500",
              }}
            />
            {cellValue}
          </span>
        );
      case "inquiriesSent":
      case "productivityTime":
      case "loadSearchTime":
      case "avgPostToContactTime":
      case "avgRate":
      case "avgRPM":
        return (
          <span className="flex flex-col">
            <span>{cellValue}</span>
            <span className="flex gap-1 items-baseline text-gray-400">
              <PercentageStatus value={percentValue} />
              vs last week
            </span>
          </span>
        );
      default:
        return cellValue;
    }
  }, []);

  return (
    <DataTable
      rows={rows}
      columns={columns}
      maxHeight="calc(100vh_-_741px)"
      renderCell={renderCell}
    />
  );
};
