import {
  getPhoneAnalyticsData,
  getEmailAnalyticsData,
  getAVGPostToContactTimeAnalyticsData,
  getAVGProductiveTimeAnalyticsData,
  getAVGRateAnalyticsData,
  getAllQueries,
  getLoadboardTimeGraph,
  getPerformanceGraph,
  getAllQueriesFilters,
} from "@/server/analytics.service";
import { TAnalyticsPeriod, TAnalyticsQueryCriteria } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserAnalyticsData = createAsyncThunk(
  "analytics/getPhoneAnalyticsData",
  async (period: TAnalyticsPeriod) => {
    const promices = [
      getPhoneAnalyticsData(period),
      getEmailAnalyticsData(period),
      getAVGPostToContactTimeAnalyticsData(period),
      getAVGProductiveTimeAnalyticsData(period),
      getAVGRateAnalyticsData(period),
    ];

    const data = await Promise.allSettled(promices);

    return {
      phoneData: data[0].status === "fulfilled" ? data[0].value : null,
      emailData: data[1].status === "fulfilled" ? data[1].value : null,
      avgPostToContactTimeData:
        data[2].status === "fulfilled" ? data[2].value : null,
      avgProductiveTimeData:
        data[3].status === "fulfilled" ? data[3].value : null,
      avgRateData: data[4].status === "fulfilled" ? data[4].value : null,
    };
  }
);

export const getUserQueriesFilters = createAsyncThunk(
  "analytics/getUserQueriesFilters",
  async () => {
    return await getAllQueriesFilters();
  }
);

export const getUserQueries = createAsyncThunk(
  "analytics/getUserQueries",
  async (criteria: TAnalyticsQueryCriteria) => {
    return await getAllQueries(criteria);
  }
);

export const getLoadboardTimeGraphData = createAsyncThunk(
  "analytics/getLoadboardTimeGraphData",
  async () => {
    return await getLoadboardTimeGraph();
  }
);

export const getPerformanceGraphData = createAsyncThunk(
  "analytics/getPerformanceGraphData",
  async (period: TAnalyticsPeriod) => {
    return await getPerformanceGraph(period);
  }
);
