import { DateRangeTabs } from "./DateRangeTabs";
import { DispatchersTable } from "./DispatchersTable";
import { DispatchersTableFilters } from "./DispatchersTableFilters";

export const DispatchersData = () => {
  return (
    <div className="flex gap-4 flex-col px-4 py-3 rounded-lg border border-gray-200">
      <div className="w-full flex justify-between items-center">
        <span>
          Dispatch Leader Board
          <span className="text-gray-950 text-lg ml-2">5</span>
          <span className="mx-2">|</span>
          {new Date().toLocaleDateString("us-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
        <DateRangeTabs />
      </div>
      <DispatchersTableFilters />
      <DispatchersTable />
    </div>
  );
};
