import { NextUIProvider } from "@nextui-org/system";

import { Provider } from "react-redux";
import { persistor, store } from "@/redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter } from "react-router-dom";
import posthog from "@/config/posthog.config";
import { useEffect } from "react";

const ProviderWrapper = ({ children }: { children: any }) => {
  useEffect(() => {
    posthog.capture("my event", { property: "value" });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <NextUIProvider>
            <ToastContainer />
            {children}
          </NextUIProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default ProviderWrapper;
