import { McReview } from "@/types";
import { callLoadConnectApi } from "./api.service";

export async function getReviews(mc: string): Promise<McReview[]> {
  const resp = await callLoadConnectApi(
    `/mcReviews?where%5Bmc%5D[equals]=${mc}`,
    "get",
    {}
  );
  const info: McReview[] = resp.data;
  return info;
}

export async function addReview(data: McReview): Promise<McReview> {
  const resp = await callLoadConnectApi(`/mcReviews`, "post", data);
  const info: McReview = resp.data;
  return info;
}
