import { Select, SelectItem, SelectProps } from "@nextui-org/react";
import { FC } from "react";
import { ISelectOption } from "@/types";

interface Props extends Omit<SelectProps, "children"> {
  label: string;
  options: ISelectOption[];
  icon?: React.ReactNode;
  isClearable?: boolean;
}

export const FitlersSelect: FC<Props> = (props) => {
  const {
    label,
    options,
    icon,
    className,
    selectedKeys,
    onSelectionChange,
    ...rest
  } = props;
  const cn = className || `max-w-56`;

  return (
    <Select
      {...rest}
      className={cn}
      classNames={{
        innerWrapper: "!bg-default-50",
        trigger: "!bg-default-50",
      }}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      disableSelectorIconRotation
      startContent={<SelectStartContent icon={icon} label={label} />}
      aria-label={label}
    >
      {options.map((option) => (
        <SelectItem key={option.id}>{option.name || "-"}</SelectItem>
      ))}
    </Select>
  );
};

const SelectStartContent: FC<{ label: string; icon?: React.ReactNode }> = ({
  label,
  icon,
}) => {
  return (
    <div className="flex items-center justify-center gap-1">
      {icon}
      {label}
    </div>
  );
};
