import { RootState, useAppDispatch } from "@/redux/store";
import { useSelector } from "react-redux";
import { TAddOn } from "@/types";
import { AddOn } from "./AddOn";
import { getBillingUpdate } from "@/redux/async/getBillingInfo";

const ADD_ONS: TAddOn[] = [
  {
    name: "Factoring + FMCSA reports",
    description:
      "Unlimited Factoring Scores and FMCSA Broker Reports are now active on your account",
    lockedText:
      "Get unlimited Factoring scores & FMCSA broker reports for just $9/month",
    featureName: "factoring",
    price: 9,
  },
];

export const AddOns = () => {
  const dispatch = useAppDispatch();
  const { features } = useSelector(
    (state: RootState) => state.authReducer.value
  );

  const isFactoringActive = features.includes("factoring");

  const handleAddonSubmit = () => {
    dispatch(getBillingUpdate());
  };

  return (
    <div className="flex flex-col gap-6">
      <h4 className="font-medium"> Add-ons</h4>
      <div>
        {ADD_ONS.map((addOn, index) => (
          <AddOn
            key={index}
            data={addOn}
            isActive={isFactoringActive}
            onSubmit={handleAddonSubmit}
          />
        ))}
      </div>
    </div>
  );
};
